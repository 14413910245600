import React, { Fragment } from "react"
import {
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap"
import bannerImg from "../assets/images/lubraicant-fundamentals-banner.jpg"
import aboutImg from "../assets/images/about.jpg"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"
const Additives = () => {
  return (
 <LayoutAnimate>
    <section className="page-banner-section">
        <Parallax
        bgImage={bannerImg}
        bgImageAlt="Call to action"
        strength={200}
        className="cta-section"
        renderLayer={percentage => (
          <div
              style={{
                  position: 'absolute',
                  background: `linear-gradient(to right, rgba(2, 23, 32,0.9),rgba(2, 23, 32,0.2))`,
                  left: '0',
                  top: '0',
                  width: '100%',
                  height: '100%',
              }}
          />
      )}
      >
       <div className="page-banner-wrapper">
       <Container className="page-banner-title-container">
          <h2>Additives</h2>
        </Container>
        <div className="breadcrumnb-wrapper">
          <Container className="breadcrumb-container pb-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Additives</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </div>
       </div>
      </Parallax>
      </section>
      <section className="contact-section layout-padding">
        <Container>
          <h2>Additives</h2>
          <p>
            Very little un additized mineral oil is sold as a lubricant. almost
            all commercial lubricant contain additive to enhance their
            performance in amounts ranging from less than 1% to 25% or more. By
            far the largest market for such additives is in the transportation
            field, Including additives for Engines and Divetrains in cars,
            Trucks, Buses, Locomotives and Ships. The function of additives can
            be summarized as:
          </p>
          <ul>
            <li>Surface Protective Additives Automotive Lubricant</li>
            <li>Performance Additives Automotive Lubricant</li>
            <li>Protective Additive Automotive Lubricant</li>
          </ul>
          <h4 className="mb-3 mt-5 text-secondary">
            Surface Protective Additives Automotive Lubricant
          </h4>
          <div className="table-wrapper">
            <Table responsive="md" className="surface-additives-table">
              <thead>
                <tr>
                  <th>Additive Type</th>
                  <th>Purpose</th>
                  <th>Typical Compounds</th>
                  <th>Functions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Anti wear and EP Agent</td>
                  <td>
                    Reduce friction and wear and prevent scoring and seizure
                  </td>
                  <td>
                    Zinc dithiophosphates, Organic phosphates, Acid phosphates,
                    Organic sulfur and Chlorine compounds, Sulfurized fats,
                    Sulfides and Disulfides
                  </td>
                  <td>
                    Chemical reaction with metal surface to form a film with
                    lower shear strength than the metal, Thereby preventing
                    metal-to-metal contact.
                  </td>
                </tr>
                <tr>
                  <td>Corrosion and Rust Inhibitors</td>
                  <td>
                    Prevent corrosion and rusting of metal parts in contact with
                    lubricant
                  </td>
                  <td>
                    Zinc dithiophosphates, metal phenolates, Basic metal
                    sulfonates, Fatty acids and amines
                  </td>
                  <td>
                    Preferential adsorption of polar constitution on metal
                    surface to provide protective film, Or Neutralize corrosive
                    acids
                  </td>
                </tr>
                <tr>
                  <td>Detergent</td>
                  <td>Keep surface free of deposits</td>
                  <td>
                    Metallo – Organic compounds of sodium, Calcium and Magnesium
                    phenolates, Phosphonates and Sulfonates
                  </td>
                  <td>
                    Chemical reaction with sludge and varnish precursors to
                    neutralize them and keep them soluble
                  </td>
                </tr>
                <tr>
                  <td>Dispersant</td>
                  <td>
                    Keep Insoluble contaminants dispersed in the lubricant
                  </td>
                  <td>
                    Alkylsuccinimides, Alkylsuccinic esters and Mannich reaction
                    products
                  </td>
                  <td>
                    Contaminant are bonded by Polar attraction to Dispersant
                    molecules, Prevented from agglomerating and kept in
                    suspension due to solubility dispersant
                  </td>
                </tr>
                <tr>
                  <td>Friction Modifiers</td>
                  <td>Alter coefficient of friction</td>
                  <td>
                    Organic fatty acids and amides, Lard oil, High molecular
                    weight organic phosphorus and Phosphoric acid esters
                  </td>
                  <td>Preferential adsorption of surface active materials</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="layout-padding2-top layout-padding-bottom">
            <h4 className="text-secondary">Performance Additives Automotive Lubricant</h4>
            <div className="table-wrapper">
              <Table responsive="md" className="surface-additives-table">
                <thead>
                  <tr>
                    <th>Additive Type</th>
                    <th>Purpose</th>
                    <th>Typical Compounds</th>
                    <th>Functions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pour point desperssant</td>
                    <td>Enable lubricant to flow at low temperatures</td>
                    <td>
                      Alkylated naphthalene and Phenolic polymers,
                      Polymethacrylates, Maleate/ Fumerate copolymer esters
                    </td>
                    <td>Modify wax crystal formation to reduce interlocking</td>
                  </tr>
                  <tr>
                    <td>Seal Swell Agent</td>
                    <td>Swell elastomeric seals</td>
                    <td>Organic phophates and aromatic hydrocarbons</td>
                    <td>
                      Chemical reaction with elastomer to cause slight swell
                    </td>
                  </tr>
                  <tr>
                    <td>Viscosity Modifiers</td>
                    <td>
                      Reduce the rate of viscosity change with temperature
                    </td>
                    <td>
                      Polymer and Copolymers of olefin, Methacrylates, Dienes or
                      Alkylated styrenes
                    </td>
                    <td>
                      Polymers expand with increasing temperature to counteract
                      oil thinning
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <h4 className="text-secondary">Protective Additives Automotive Lubricant</h4>
          <div className="table-wrapper">
            <Table responsive="md" className="surface-additives-table">
              <thead>
                <tr>
                  <th>Additive Type</th>
                  <th>Purpose</th>
                  <th>Typical Compounds</th>
                  <th>Functions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Antifoam Agent</td>
                  <td>Prevent lubricant from forming a persistent foam</td>
                  <td>Silicone polymers, Organic copolymers</td>
                  <td>Reduce surface tension to speed collapse of foam</td>
                </tr>
                <tr>
                  <td>Antioxidant</td>
                  <td>Retard oxidative decomposition</td>
                  <td>
                    Zinc dithiophosphates, Hindered phenols, Sulfurized phenols
                    aromatic amines
                  </td>
                  <td>
                    Decompose preoxides and terminate free radical reaction
                  </td>
                </tr>
                <tr>
                  <td>Metal Deactivator</td>
                  <td>Reduce catalytic effect of metals on oxidation rate</td>
                  <td>
                    Organic complexes containing nitrogen Or Sulfur, Amines,
                    Sulfides and Phosphites
                  </td>
                  <td>
                    Form inactive film on metal surfaces by complexing with
                    metallic ions
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      </section>
 </LayoutAnimate>
  )
}

export default Additives
